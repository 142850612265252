import React, { useEffect } from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Image from "../components/image"
import SEO from "../components/seo"

import ReactGA from "react-ga"

const IndexPage = () => {
  useEffect(() => {
    ReactGA.initialize("UA-167954227-1")
    ReactGA.pageview("/smart-brf-splash")
  }, [])

  const handleGoToGrannskap = () => {
    ReactGA.event({
      category: "User",
      action: "Clicked Grannskap Link",
    })
    window.location = "https://grannskap.se/"
  }

  return (
    <Layout>
      <SEO
        title="Hemma på nätet"
        description="Grannskap® är en digital plattform för grannsämja och grannsamverkan."
        author="Grannskap AB"
      />
      <div className="relative bg-white overflow-hidden">
        <div className="max-w-screen-xl mx-auto ">
          <div className="relative z-10 pb-8 bg-white sm:pb-16 md:pb-20 lg:max-w-2xl lg:w-full lg:min-h-screen lg:pb-28 xl:pb-32  lg:flex lg:flex-col lg:justify-center">
            <svg
              className="hidden lg:block absolute right-0 inset-y-0 h-full w-48 text-white transform translate-x-1/2"
              fill="currentColor"
              viewBox="0 0 100 100"
              preserveAspectRatio="none"
            >
              <polygon points="50,0 100,0 50,100 0,100" />
            </svg>

            <div className="relative pt-6 px-4 sm:px-6 lg:px-8">
              <nav className="relative flex items-center justify-between sm:justify-center lg:justify-between sm:h-10 lg:justify-start">
                <div className="flex items-center flex-grow flex-shrink-0 lg:flex-grow-0">
                  <div className="flex items-center justify-between sm:justify-center lg:justify-between w-full lg:w-auto">
                    <svg
                      className="h-8 w-auto sm:h-10"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 330.6 272.72"
                    >
                      <path
                        id="symbol"
                        d="M458.16,253.22h0l-89.52-67.44h0a17.88,17.88,0,0,0-21.52,0h0l-72.05,54.28L216.94,198h0A11,11,0,0,0,204,198h0l-64.75,46.84h0a11,11,0,0,0-4.57,8.95v82.65a11,11,0,0,0,11,11h28.94a4.18,4.18,0,0,1,3.11,1.39h0l29.6,33v0a4.17,4.17,0,0,0,7.28-2.77V351.65a4.17,4.17,0,0,1,4.17-4.17h56.41a11,11,0,0,0,11-11V260.29l9.16,6.62h0a11,11,0,0,1,4.58,8.94v82.65a11,11,0,0,1-11,11h-38.5v9.56A17.9,17.9,0,0,0,268.36,397h75.79a6.86,6.86,0,0,1,6.86,6.86V448a6.87,6.87,0,0,0,12,4.57h0l47.64-53.29h0a6.85,6.85,0,0,1,5.12-2.29H447.4a17.9,17.9,0,0,0,17.9-17.91V267.52A17.84,17.84,0,0,0,458.16,253.22Z"
                        transform="translate(-134.7 -182.17)"
                        fill="#09796e"
                      />
                    </svg>
                  </div>
                </div>
              </nav>
            </div>

            <div className="mt-10 mx-auto lg:mx-0 max-w-screen-xl px-4 sm:mt-12 sm:px-6 md:mt-16 lg:mt-20 lg:px-8 xl:mt-28">
              <div className="sm:text-center lg:text-left">
                <h2 className="text-4xl tracking-tight leading-10 font-extrabold text-gray-900 sm:text-5xl sm:leading-none md:text-6xl">
                  Smart Brf blir
                  <br />
                  <span className="text-primary">
                    Grannskap<span className="text-xl">®</span>
                  </span>
                </h2>
                <p className="mt-3 text-base text-gray-500 sm:mt-5 sm:text-lg sm:max-w-xl sm:mx-auto md:mt-5 md:text-xl lg:mx-0">
                  Vår populära webbtjänst för bostadsrättsföreningar kommer
                  under 2022 bli en del av Grannskap. Vi är samma team bakom
                  tjänsten och även det framgångsrika konceptet förblir det
                  samma - men i en helt ny uppdaterad plattform. Många spännande
                  nyheter och förbättringar är att vänta! <br />
                  <br />I väntan på lanseringen har vi nu stängt ned denna sajt,
                  smartbrf.se, och hänvisar till intresseanmälan på
                  grannskap.se. Varmt välkomna!
                </p>
                <div className="mt-5 sm:mt-8 sm:flex sm:justify-center lg:justify-start">
                  <div className="rounded-md shadow">
                    <a
                      href="#"
                      className="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base leading-6 font-medium rounded-md text-white bg-primary hover:bg-primary-light focus:outline-none focus:shadow-outline transition duration-150 ease-in-out md:py-4 md:text-lg md:px-10"
                      onClick={() => handleGoToGrannskap()}
                    >
                      Gå till Grannskap©
                    </a>
                  </div>
                </div>
                <p className="mt-3 text-base text-gray-500 sm:mt-5 sm:text-lg sm:max-w-xl sm:mx-auto md:mt-5 md:text-xl lg:mx-0">
                  Befintlig kund som behöver support? support@smartbrf.se
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2">
          <Image />
        </div>
      </div>
    </Layout>
  )
}

export default IndexPage
